/*=========================================================================================
  File Name: resources.js
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  Description: {
    name: "Description",
    slug: "description",
    i18n: "Description"
  },
  CarryInLastPeriod: {
    name: "Carry in from last period",
    slug: "CarryInLastPeriod",
    i18n: "CarryInLastPeriod"
  },
  CurrentPeriod: {
    name: "Current period",
    slug: "CurrentPeriod",
    i18n: "CurrentPeriod"
  },
  CarryNextPeriod: {
    name: "Carry forward to next period",
    slug: "CarryNextPeriod",
    i18n: "CarryNextPeriod"
  },
  BusinessCenters: {
    name: "Businesses centers",
    slug: "BusinessCenters",
    i18n: "BusinessCenters"
  },
  BusinessCenter: {
    name: "Business center",
    slug: "BusinessCenter",
    i18n: "BusinessCenter"
  },
  Center: {
    name: "Center",
    slug: "Center",
    i18n: "Center"
  },
  NewCustomers90: {
    name: "New customers the last 90 days",
    slug: "NewCustomers90",
    i18n: "NewCustomers90"
  },
  DirectSales90: {
    name: "Tourism sales the last 90 days",
    slug: "DirectSales90",
    i18n: "DirectSales90"
  },
  DirectSales365: {
    name: "Tourism sales the last 365 days",
    slug: "DirectSales365",
    i18n: "DirectSales365"
  },
  Last5Periods: {
    name: "Last five periods",
    slug: "Last5Periods",
    i18n: "Last5Periods"
  },
  Number: {
    name: "Número",
    slug: "Number",
    i18n: "Number"
  },
  PVAmount: {
    name: "PV Amount",
    slug: "PVAmount",
    i18n: "PVAmount"
  },
  Period: {
    name: "Period",
    slug: "Period",
    i18n: "Period"
  },
  DistCountryCode: {
    name: "Dist. country code",
    slug: "DistCountryCode",
    i18n: "DistCountryCode"
  },
  ShipToCountry: {
    name: "Ship to country",
    slug: "ShipToCountry",
    i18n: "ShipToCountry"
  },
  ShipToName: {
    name: "Ship to name",
    slug: "ShipToName",
    i18n: "ShipToName"
  },
  Date: {
    name: "Date",
    slug: "Date",
    i18n: "Date"
  },
  Options: {
    name: "Options",
    slug: "Options",
    i18n: "Options"
  },
  Next: {
    name: "Next",
    slug: "Next",
    i18n: "Next"
  },
  Back: {
    name: "Back",
    slug: "Back",
    i18n: "Back"
  },
  Finish: {
    name: "Finish",
    slug: "Finish",
    i18n: "Finish"
  },
  LastWeek: {
    name: "Last week",
    slug: "LastWeek",
    i18n: "LastWeek"
  },
  Currently: {
    name: "Currently",
    slug: "Currently",
    i18n: "Currently"
  },
  Total: {
    name: "Total",
    slug: "Total",
    i18n: "Total"
  },
  Volumen: {
    name: "Volumen",
    slug: "Volumen",
    i18n: "Volumen"
  },
  Available: {
    name: "Available",
    slug: "Available",
    i18n: "Available"
  },
  Profile: {
    name: "Profile",
    slug: "Profile",
    i18n: "Profile"
  },
  View: {
    name: "View",
    slug: "View",
    i18n: "View"
  },
  Download: {
    name: "Download",
    slug: "Download",
    i18n: "Download"
  },
  OrderHistory: {
    name: "Orders history",
    slug: "OrderHistory",
    i18n: "OrderHistory"
  },
  BasicInformation: {
    name: "Basic information",
    slug: "BasicInformation",
    i18n: "BasicInformation"
  },
  DocumentNumber: {
    name: "Document number",
    slug: "DocumentNumber",
    i18n: "DocumentNumber"
  },
  BirthDate: {
    name: "Birth date",
    slug: "BirthDate",
    i18n: "BirthDate"
  },
  Email: {
    name: "Email",
    slug: "Email",
    i18n: "Email"
  },
  Mobile: {
    name: "Mobile",
    slug: "Mobile",
    i18n: "Mobile"
  },
  Gender: {
    name: "Gender",
    slug: "Gender",
    i18n: "Gender"
  },
  CountryOperation: {
    name: "Country operation",
    slug: "CountryOperation",
    i18n: "CountryOperation"
  },
  Nationality: {
    name: "Nationality",
    slug: "Nationality",
    i18n: "Nationality"
  },
  ResidenceCity: {
    name: "Residence city",
    slug: "ResidenceCity",
    i18n: "ResidenceCity"
  },
  Address: {
    name: "Address",
    slug: "Address",
    i18n: "Address"
  },
  Return: {
    name: "Return",
    slug: "Return",
    i18n: "Return"
  },
  AllRightsReserved: {
    name: "All rights reserved",
    slug: "AllRightsReserved",
    i18n: "AllRightsReserved"
  },
  HandCraftedMMade: {
    name: "Hand-craft and made with",
    slug: "HandCraftedMMade",
    i18n: "HandCraftedMMade"
  },
  Invoice: {
    name: "Factura",
    slug: "Invoice",
    i18n: "Invoice"
  },
  OrderNumber: {
    name: "Order number",
    slug: "OrderNumber",
    i18n: "OrderNumber"
  },
  OrderDate: {
    name: "Order date",
    slug: "OrderDate",
    i18n: "OrderDate"
  },
  ShipTo: {
    name: "Ship to",
    slug: "ShipTo",
    i18n: "ShipTo"
  },
  BillTo: {
    name: "Bill to",
    slug: "BillTo",
    i18n: "BillTo"
  },
  Item: {
    name: "Item",
    slug: "Item",
    i18n: "Item"
  },
  Code: {
    name: "Code",
    slug: "Code",
    i18n: "Code"
  },
  QtyShip: {
    name: "Quantity ship",
    slug: "QtyShip",
    i18n: "QtyShip"
  },
  QtyOrder: {
    name: "Quantity order",
    slug: "QtyOrder",
    i18n: "QtyOrder"
  },
  Price: {
    name: "Price",
    slug: "Price",
    i18n: "Price"
  },
  Tax: {
    name: "Tax",
    slug: "Tax",
    i18n: "Tax"
  },
  Taxable: {
    name: "Taxable",
    slug: "Taxable",
    i18n: "Taxable"
  },
  Subtotal: {
    name: "Subtotal",
    slug: "Subtotal",
    i18n: "Subtotal"
  },
  Comments: {
    name: "Comments",
    slug: "Comments",
    i18n: "Comments"
  },
  ShippingAndHandling: {
    name: "Shipping and handling",
    slug: "ShippingAndHandling",
    i18n: "ShippingAndHandling"
  },
  TotalAmountDue: {
    name: "Total amount due",
    slug: "TotalAmountDue",
    i18n: "TotalAmountDue"
  },
  PaymentsReceived: {
    name: "Payments received",
    slug: "PaymentsReceived",
    i18n: "PaymentsReceived"
  },
  ReceiptAmount: {
    name: "Receipt amount",
    slug: "ReceiptAmount",
    i18n: "ReceiptAmount"
  },
  TotalCashReceipts: {
    name: "Total cash receipts",
    slug: "TotalCashReceipts",
    i18n: "TotalCashReceipts"
  },
  InvoceBalance: {
    name: "Invoice balance",
    slug: "InvoceBalance",
    i18n: "InvoceBalance"
  },
  Close: {
    name: "Close",
    slug: "Close",
    i18n: "Close"
  },
  NoAvailableData: {
    name: "No available data",
    slug: "NoAvailableData",
    i18n: "NoAvailableData"
  },
  LeftVolumen: {
    name: "Left volumen",
    slug: "LeftVolumen",
    i18n: "LeftVolumen"
  },
  RightVolumen: {
    name: "Left volumen",
    slug: "RightVolumen",
    i18n: "RightVolumen"
  },
  ExportAs: {
    name: "Export as",
    slug: "ExportAs",
    i18n: "ExportAs"
  },
  Logout: {
    name: "Logout",
    slug: "Logout",
    i18n: "Logout"
  },
  LeftBvTravelPoint: {
    name: "Left - Travel points (BV)",
    slug: "LeftBvTravelPoint",
    i18n: "LeftBvTravelPoint"
  },
  RightBvTravelPoint: {
    name: "Right - Travel points (BV)",
    slug: "RightBvTravelPoint",
    i18n: "RightBvTravelPoint"
  },
  Qualifications: {
    name: "Qualifications",
    slug: "Qualifications",
    i18n: "Qualifications"
  },
  Rank: {
    name: "Rank",
    slug: "Rank",
    i18n: "Rank"
  },
  VolumenPersonal: {
    name: "Volumen personal",
    slug: "VolumenPersonal",
    i18n: "VolumenPersonal"
  },
  TotalVolumen: {
    name: "Volumen total",
    slug: "TotalVolumen",
    i18n: "TotalVolumen"
  },
  ViewEnrolledsTotal: {
    name: "View enrollees total",
    slug: "ViewEnrolledsTotal",
    i18n: "ViewEnrolledsTotal"
  },
  CyclesRequired: {
    name: "Cycles required",
    slug: "CyclesRequired",
    i18n: "CyclesRequired"
  },
  CyclesTotal: {
    name: "Cycles total made",
    slug: "CyclesTotal",
    i18n: "CyclesTotal"
  },
  PersonalEnrollees: {
    name: "Personal enrollees",
    slug: "PersonalEnrollees",
    i18n: "PersonalEnrollees"
  },
  Left: {
    name: "Left",
    slug: "Left",
    i18n: "Left"
  },
  Right: {
    name: "Right",
    slug: "Right",
    i18n: "Right"
  },
  SinceWeek: {
    name: "Since week",
    slug: "SinceWeek",
    i18n: "SinceWeek"
  },
  UntilWeek: {
    name: "Until week",
    slug: "UntilWeek",
    i18n: "UntilWeek"
  },
  Select: {
    name: "Select",
    slug: "Select",
    i18n: "Select"
  },
  Search: {
    name: "Search",
    slug: "Search",
    i18n: "Search"
  },
  Summary: {
    name: "Summary",
    slug: "Summary",
    i18n: "Summary"
  },
  StartDate: {
    name: "Start date",
    slug: "StartDate",
    i18n: "StartDate"
  },
  EndDate: {
    name: "End date",
    slug: "EndDate",
    i18n: "EndDate"
  },
  CheckDate: {
    name: "Check date",
    slug: "CheckDate",
    i18n: "CheckDate"
  },
  CheckAmount: {
    name: "Check amount",
    slug: "CheckAmount",
    i18n: "CheckAmount"
  },
  EarningsAmount: {
    name: "Earnings amount",
    slug: "EarningsAmount",
    i18n: "EarningsAmount"
  },
  CurrencyCode: {
    name: "Currency code",
    slug: "CurrencyCode",
    i18n: "CurrencyCode"
  },
  Currency: {
    name: "Currency",
    slug: "Currency",
    i18n: "Currency"
  },
  TransactionNumber: {
    name: "Transaction number",
    slug: "TransactionNumber",
    i18n: "TransactionNumber"
  },
  EarningsSummaryByPeriod: {
    name: "Earnings summary by period",
    slug: "EarningsSummaryByPeriod",
    i18n: "EarningsSummaryByPeriod"
  },
  Commissions: {
    name: "Commissions",
    slug: "Commissions",
    i18n: "Commissions"
  },
  RetailCommission: {
    name: "Retail commission",
    slug: "RetailCommission",
    i18n: "RetailCommission"
  },
  FastStart: {
    name: "Fast start",
    slug: "FastStart",
    i18n: "FastStart"
  },
  CycleCommission: {
    name: "Cycle commission",
    slug: "CycleCommission",
    i18n: "CycleCommission"
  },
  MatchingBonus: {
    name: "Matching bonus",
    slug: "MatchingBonus",
    i18n: "MatchingBonus"
  },
  BaseBonus: {
    name: "Base bonus",
    slug: "BaseBonus",
    i18n: "BaseBonus"
  },
  LifestyleBonus: {
    name: "Lifestyle bonus",
    slug: "LifestyleBonus",
    i18n: "LifestyleBonus"
  },
  EarningsByCommissionType: {
    name: "Earnings by commission type",
    slug: "EarningsByCommissionType",
    i18n: "EarningsByCommissionType"
  },
  PeriodDetails: {
    name: "PeriodDetails",
    slug: "PeriodDetails",
    i18n: "PeriodDetails"
  },
  DownloadPDF: {
    name: "Download PDF",
    slug: "DownloadPDF",
    i18n: "DownloadPDF"
  },
  Detail: {
    name: "Detail",
    slug: "Detail",
    i18n: "Detail"
  },
  DistributorID: {
    name: "Distributor ID",
    slug: "DistributorID",
    i18n: "DistributorID"
  },
  Name: {
    name: "Name",
    slug: "Name",
    i18n: "Name"
  },
  PaidRank: {
    name: "Paid rank",
    slug: "PaidRank",
    i18n: "PaidRank"
  },
  PinRank: {
    name: "Pin rank",
    slug: "PinRank",
    i18n: "PinRank"
  },
  PercentContributed: {
    name: "Percent contributed",
    slug: "PercentContributed",
    i18n: "PercentContributed"
  },
  Percent: {
    name: "Percent",
    slug: "Percent",
    i18n: "Percent"
  },
  AmountContributed: {
    name: "Amount contributed",
    slug: "AmountContributed",
    i18n: "AmountContributed"
  },
  Amount: {
    name: "Amount",
    slug: "Amount",
    i18n: "Amount"
  },
  BinaryTree: {
    name: "Binary tree",
    slug: "BinaryTree",
    i18n: "BinaryTree"
  },
  RequiredEnrolleds: {
    name: "Required enrollees",
    slug: "RequiredEnrollees",
    i18n: "RequiredEnrolleds"
  },
  Level: {
    name: "Level",
    slug: "Level",
    i18n: "Level"
  },
  EndRank: {
    name: "End rank",
    slug: "EndRank",
    i18n: "EndRank"
  },
  Side: {
    name: "Side",
    slug: "Side",
    i18n: "Side"
  },
  Sponsor: {
    name: "Sponsor",
    slug: "Sponsor",
    i18n: "Sponsor"
  },
  FromLevel: {
    name: "From level",
    slug: "FromLevel",
    i18n: "FromLevel"
  },
  UntilLevel: {
    name: "Until level",
    slug: "UntilLevel",
    i18n: "UntilLevel"
  },
  All: {
    name: "All",
    slug: "All",
    i18n: "All"
  },
  Customer: {
    name: "Customer",
    slug: "Customer",
    i18n: "Customer"
  },
  Terminated: {
    name: "Terminated",
    slug: "Terminated",
    i18n: "Terminated"
  },
  Distributor: {
    name: "Distributor",
    slug: "Distributor",
    i18n: "Distributor"
  },
  MemberType: {
    name: "Member type",
    slug: "MemberType",
    i18n: "MemberType"
  },
  Suspended: {
    name: "Suspended",
    slug: "Suspended",
    i18n: "Suspended"
  },
  AmountRows: {
    name: "Amount rows",
    slug: "AmountRows",
    i18n: "AmountRows"
  },
  Status: {
    name: "Status",
    slug: "Status",
    i18n: "Status"
  },
  Associated: {
    name: "Associated",
    slug: "Associated",
    i18n: "Associated"
  },
  Silver: {
    name: "Silver",
    slug: "Silver",
    i18n: "Silver"
  },
  Gold: {
    name: "Gold",
    slug: "Gold",
    i18n: "Gold"
  },
  Platinum: {
    name: "Platinum",
    slug: "Platinum",
    i18n: "Platinum"
  },
  Diamond: {
    name: "Diamond",
    slug: "Diamond",
    i18n: "Diamond"
  },
  DoubleDiamond: {
    name: "Double Diamond",
    slug: "DoubleDiamond",
    i18n: "DoubleDiamond"
  },
  TripleDiamond: {
    name: "Triple Diamond",
    slug: "TripleDiamond",
    i18n: "TripleDiamond"
  },
  Crown: {
    name: "Crown",
    slug: "Crown",
    i18n: "Crown"
  },
  CrownAmbassador: {
    name: "Crown Ambassador",
    slug: "CrownAmbassador",
    i18n: "CrownAmbassador"
  },
  Start: {
    name: "Start",
    slug: "Start",
    i18n: "Start"
  },
  Unclassified: {
    name: "Unclassified",
    slug: "Unclassified",
    i18n: "Unclassified"
  },
  Login: {
    name: "Login",
    slug: "Login",
    i18n: "Login"
  },
  ForgotPassword: {
    name: "Forgot your password?",
    slug: "ForgotPassword",
    i18n: "ForgotPassword"
  },
  Password: {
    name: "Password",
    slug: "Password",
    i18n: "Password"
  },
  RecoverPassword: {
    name: "Recover your password",
    slug: "RecoverPassword",
    i18n: "RecoverPassword"
  },
  Recover: {
    name: "Recover",
    slug: "Recover",
    i18n: "Recover"
  },
  ConfirmPassword: {
    name: "Confirm password",
    slug: "ConfirmPassword",
    i18n: "ConfirmPassword"
  },
  VerificationCode: {
    name: "Verification code",
    slug: "VerificationCode",
    i18n: "VerificationCode"
  },
  ResetPassword: {
    name: "Reset password",
    slug: "ResetPassword",
    i18n: "ResetPassword"
  },
  Reset: {
    name: "Reset",
    slug: "Reset",
    i18n: "Reset"
  },
  StatusByPeriod: {
    name: "Status by period",
    slug: "StatusByPeriod",
    i18n: "StatusByPeriod"
  },
  CustomizedCommissions: {
    name: "Customized commissions",
    slug: "CustomizedCommissions",
    i18n: "CustomizedCommissions"
  },
  TipAndCustomizedFee: {
    name: "Tips and customized Fee",
    slug: "TipAndCustomizedFee",
    i18n: "TipAndCustomizedFee"
  },
  FeeTotal: {
    name: "Fee total",
    slug: "FeeTotal",
    i18n: "FeeTotal"
  },
  TipsTotal: {
    name: "Tips total",
    slug: "TipsTotal",
    i18n: "TipsTotal"
  },
  Pay: {
    name: "Pay",
    slug: "Pay",
    i18n: "Pay"
  },
  BookingCode: {
    name: "Booking code",
    slug: "BookingCode",
    i18n: "BookingCode"
  },
  Type: {
    name: "Type",
    slug: "Type",
    i18n: "Type"
  },
  Transfer: {
    name: "Transfer",
    slug: "Transfer",
    i18n: "Transfer"
  },
  Congratulations: {
    name: "Congratulations",
    slug: "Congratulations",
    i18n: "Congratulations"
  },
  Paid: {
    name: "Paid",
    slug: "Paid",
    i18n: "Paid"
  },
  WithoutPay: {
    name: "Without pay",
    slug: "WithoutPay",
    i18n: "WithoutPay"
  },
  TotalTransactions: {
    name: "Total transactions",
    slug: "TotalTransactions",
    i18n: "TotalTransactions"
  },
  Tip: {
    name: "Tip",
    slug: "Tip",
    i18n: "Tip"
  },
  CustomizedFee: {
    name: "Customized fee",
    slug: "CustomizedFee",
    i18n: "CustomizedFee"
  },
  AdjustmentTip: {
    name: "Tip adjustment",
    slug: "AdjustmentTip",
    i18n: "AdjustmentTip"
  },
  AdjustmentCustomizedFee: {
    name: "Customized fee adjustment",
    slug: "AdjustmentCustomizedFee",
    i18n: "AdjustmentCustomizedFee"
  },
  Create: {
    name: "Create",
    slug: "Create",
    i18n: "Create"
  },
  TotalPendingCharges: {
    name: "Total pending charges",
    slug: "TotalPendingCharges",
    i18n: "TotalPendingCharges"
  },
  Update: {
    name: "Update",
    slug: "Update",
    i18n: "Update"
  },
  Save: {
    name: "Save",
    slug: "Save",
    i18n: "Save"
  },
  ThemeCustomized: {
    name: "Theme customized",
    slug: "ThemeCustomized",
    i18n: "ThemeCustomized"
  },
  LayoutType: {
    name: "Layout type",
    slug: "LayoutType",
    i18n: "LayoutType"
  },
  Vertical: {
    name: "Vertical",
    slug: "Vertical",
    i18n: "Vertical"
  },
  Horizontal: {
    name: "Horizontal",
    slug: "Horizontal",
    i18n: "Horizontal"
  },
  Color: {
    name: "Color",
    slug: "Color",
    i18n: "Color"
  },
  Mode: {
    name: "Mode",
    slug: "Mode",
    i18n: "Mode"
  },
  Light: {
    name: "Light",
    slug: "Light",
    i18n: "Light"
  },
  Dark: {
    name: "Dark",
    slug: "Dark",
    i18n: "Dark"
  },
  SemiDark: {
    name: "Semi-dark",
    slug: "SemiDark",
    i18n: "SemiDark"
  },
  CollapseSidebar: {
    name: "Collapse Sidebar",
    slug: "CollapseSidebar",
    i18n: "CollapseSidebar"
  },
  NavbarColor: {
    name: "Navbar color",
    slug: "NavbarColor",
    i18n: "NavbarColor"
  },
  NavbarType: {
    name: "Navbar type",
    slug: "NavbarType",
    i18n: "NavbarType"
  },
  FooterType: {
    name: "Footer type",
    slug: "FooterType",
    i18n: "FooterType"
  },
  RightToLeft: {
    name: "Derecha a la izquierda",
    slug: "RightToLeft",
    i18n: "RightToLeft"
  },
  Animation: {
    name: "Animation",
    slug: "Animation",
    i18n: "Animation"
  },
  Hidden: {
    name: "Hidden",
    slug: "Hidden",
    i18n: "Hidden"
  },
  Static: {
    name: "Static",
    slug: "Static",
    i18n: "Static"
  },
  Sticky: {
    name: "Sticky",
    slug: "Sticky",
    i18n: "Sticky"
  },
  Floating: {
    name: "Floating",
    slug: "Floating",
    i18n: "Floating"
  },
  Bookings: {
    name: "Bookings",
    slug: "Bookings",
    i18n: "Bookings"
  },
  BookingDetail: {
    name: "Booking detail",
    slug: "BookingDetail",
    i18n: "BookingDetail"
  },
  AccountNumber: {
    name: "AccountNumber",
    slug: "AccountNumber",
    i18n: "AccountNumber"
  },
  ContactName: {
    name: "ContactName",
    slug: "ContactName",
    i18n: "ContactName"
  },
  Filters: {
    name: "Filters",
    slug: "Filters",
    i18n: "Filters"
  },
  Supplier: {
    name: "Supplier",
    slug: "Supplier",
    i18n: "Supplier"
  },
  Managed: {
    name: "Managed",
    slug: "Managed",
    i18n: "Managed"
  },
  TicketNumber: {
    name: "Ticket number",
    slug: "TicketNumber",
    i18n: "TicketNumber"
  },
  Source: {
    name: "Source",
    slug: "Source",
    i18n: "Source"
  },
  WayToPay: {
    name: "WayToPay",
    slug: "WayToPay",
    i18n: "WayToPay"
  },
  NetValue: {
    name: "NetValue",
    slug: "NetValue",
    i18n: "NetValue"
  },
  TaxesValue: {
    name: "TaxesValue",
    slug: "TaxesValue",
    i18n: "TaxesValue"
  },
  FeeValue: {
    name: "FeeValue",
    slug: "FeeValue",
    i18n: "FeeValue"
  },
  FeeCustomized: {
    name: "FeeCustomized",
    slug: "FeeCustomized",
    i18n: "FeeCustomized"
  },
  MerchantValue: {
    name: "MerchantValue",
    slug: "MerchantValue",
    i18n: "MerchantValue"
  },
  DiscountValue: {
    name: "DiscountValue",
    slug: "DiscountValue",
    i18n: "DiscountValue"
  },
  TotalValue: {
    name: "TotalValue",
    slug: "TotalValue",
    i18n: "TotalValue"
  },
  OtherValue: {
    name: "OtherValue",
    slug: "OtherValue",
    i18n: "OtherValue"
  },
  ProductType: {
    name: "ProductType",
    slug: "ProductType",
    i18n: "ProductType"
  },
  Origin: {
    name: "Origin",
    slug: "Origin",
    i18n: "Origin"
  },
  Destination: {
    name: "Destination",
    slug: "Destination",
    i18n: "Destination"
  },
  SupplierName: {
    name: "SupplierName",
    slug: "SupplierName",
    i18n: "SupplierName"
  },
  Itinerary: {
    name: "Itinerary",
    slug: "Itinerary",
    i18n: "Itinerary"
  },
  Passengers: {
    name: "Passengers",
    slug: "Passengers",
    i18n: "Passengers"
  },
  PassengerType: {
    name: "PassengerType",
    slug: "PassengerType",
    i18n: "PassengerType"
  },
  DocumentType: {
    name: "DocumentType",
    slug: "DocumentType",
    i18n: "DocumentType"
  },
  Phone: {
    name: "Phone",
    slug: "Phone",
    i18n: "Phone"
  },
  FareValue: {
    name: "FareValue",
    slug: "FareValue",
    i18n: "FareValue"
  },
  Merchant: {
    name: "Merchant",
    slug: "Merchant",
    i18n: "Merchant"
  },
  Value: {
    name: "Value",
    slug: "Value",
    i18n: "Value"
  },
  Country: {
    name: "Country",
    slug: "Country",
    i18n: "Country"
  },
  PaymentMethod: {
    name: "Payment method",
    slug: "PaymentMethod",
    i18n: "PaymentMethod"
  },
  Stage: {
    name: "Stage",
    slug: "Stage",
    i18n: "Stage"
  },
  Yes: {
    name: "Yes",
    slug: "Yes",
    i18n: "Yes"
  },
  No: {
    name: "No",
    slug: "No",
    i18n: "No"
  },
  List: {
    name: "List",
    slug: "List",
    i18n: "List"
  },
  Clean: {
    name: "Clean",
    slug: "Clean",
    i18n: "Clean"
  },
  Payment: {
    name: "Payment",
    slug: "Payment",
    i18n: "Payment"
  },
  ExpirationDate: {
    name: "Expiration date",
    slug: "ExpirationDate",
    i18n: "ExpirationDate"
  },
  Purchases: {
    name: "Purchases",
    slug: "Purchases",
    i18n: "Purchases"
  },
  CreatedBy: {
    name: "Created by",
    slug: "CreatedBy",
    i18n: "CreatedBy"
  },
  Tracer: {
    name: "Tracer",
    slug: "Tracer",
    i18n: "Tracer"
  },
  Guides: {
    name: "Guides",
    slug: "Guides",
    i18n: "Guides"
  },
  GuideCreationDate: {
    name: "Guide creation date",
    slug: "GuideCreationDate",
    i18n: "GuideCreationDate"
  },
  Guide: {
    name: "Guide",
    slug: "Guide",
    i18n: "Guide"
  },
  EmailText: {
    name: "Email text",
    slug: "EmailText",
    i18n: "EmailText"
  },
  Send: {
    name: "Send",
    slug: "Send",
    i18n: "Send"
  },
  LastName: {
    name: "Last name",
    slug: "LastName",
    i18n: "LastName"
  },
  Subject: {
    name: "Subject",
    slug: "Subject",
    i18n: "Subject"
  },
  Credit: {
    name: "RegisterCard",
    slug: "RegisterCard",
    i18n: "RegisterCard"
  },
  Settings: {
    name: "Settings",
    slug: "Settings",
    i18n: "Settings"
  },
  MoreInformation: {
    name: "MoreInformation",
    slug: "MoreInformation",
    i18n: "MoreInformation"
  },
  FeeNote: {
    name: "FeeNote",
    slug: "FeeNote",
    i18n: "FeeNote"
  },
  CardRegistered: {
    name: "CardRegistered",
    slug: "CardRegistered",
    i18n: "CardRegistered"
  },
  CardNote: {
    name: "CardNote",
    slug: "CardNote",
    i18n: "CardNote"
  },
  NewCard: {
    name: "NewCard",
    slug: "NewCard",
    i18n: "NewCard"
  },
  CardType: {
    name: "Card type",
    slug: "CardType",
    i18n: "CardType"
  },
  CardNumber: {
    name: "Card number",
    slug: "CardNumber",
    i18n: "CardNumber"
  },
  ExpirationMonth: {
    name: "Expiration mounth",
    slug: "ExpirationMonth",
    i18n: "ExpirationMonth"
  },
  ExpirationYear: {
    name: "Expiration year",
    slug: "ExpirationYear",
    i18n: "ExpirationYear"
  },
  SecurityCode: {
    name: "Security code",
    slug: "SecurityCode",
    i18n: "SecurityCode"
  },
  ZipCode: {
    name: "Zip code",
    slug: "ZipCode",
    i18n: "ZipCode"
  },
  Names: {
    name: "Names",
    slug: "Names",
    i18n: "Names"
  },
  Surnames: {
    name: "Surnames",
    slug: "Surnames",
    i18n: "Surnames"
  },
  SelectCountry: {
    name: "Select Country",
    slug: "SelectCountry",
    i18n: "SelectCountry"
  },
  Cancel: {
    name: "Cancel",
    slug: "Cancel",
    i18n: "Cancel"
  },
  SelectState: {
    name: "Select State",
    slug: "SelectState",
    i18n: "SelectState"
  },
  State: {
    name: "State",
    slug: "State",
    i18n: "State"
  },
  EmailIncorrect: {
    name: "EmailIncorrect",
    slug: "EmailIncorrect",
    i18n: "EmailIncorrect"
  },
  CreationDate: {
    name: "Creation date",
    slug: "CreationDate",
    i18n: "CreationDate"
  },
  Coupons: {
    name: "Coupons",
    slug: "Coupons",
    i18n: "Coupons"
  },
  Generate: {
    name: "Generate",
    slug: "Generate",
    i18n: "Generate"
  },
  Upgrade: {
    name: "Upgrade",
    slug: "Upgrade",
    i18n: "Upgrade"
  },
  Membership: {
    name: "Membership",
    slug: "Membership",
    i18n: "Membership"
  },
  MonthlyPayment: {
    name: "Monthly payment",
    slug: "MonthlyPayment",
    i18n: "MonthlyPayment"
  },
  AutomaticMonthlyPayment: {
    name: "Automatic monthly payment",
    slug: "AutomaticMonthlyPayment",
    i18n: "AutomaticMonthlyPayment"
  },
  Buy: {
    name: "Buy",
    slug: "Buy",
    i18n: "Buy"
  },
  CreditCard: {
    name: "Credit card",
    slug: "CreditCard",
    i18n: "CreditCard"
  },
  UserName: {
    name: "User name",
    slug: "UserName",
    i18n: "UserName"
  },
  TermsConditionsTitle: {
    name: "TermsConditionsTitle",
    slug: "TermsConditionsTitle",
    i18n: "TermsConditionsTitle"
  },
  TermsConditionsContent1: {
    name: "TermsConditionsContent1",
    slug: "TermsConditionsContent1",
    i18n: "TermsConditionsContent1"
  },
  TermsConditionsContent2: {
    name: "TermsConditionsContent2",
    slug: "TermsConditionsContent2",
    i18n: "TermsConditionsContent2"
  },
  TermsConditionsContent3: {
    name: "TermsConditionsContent3",
    slug: "TermsConditionsContent3",
    i18n: "TermsConditionsContent3"
  },
  TermsConditionsContent4: {
    name: "TermsConditionsContent4",
    slug: "TermsConditionsContent4",
    i18n: "TermsConditionsContent4"
  },
  TermsConditionsContent5: {
    name: "TermsConditionsContent5",
    slug: "TermsConditionsContent5",
    i18n: "TermsConditionsContent5"
  },
  TermsConditionsContent6: {
    name: "TermsConditionsContent6",
    slug: "TermsConditionsContent6",
    i18n: "TermsConditionsContent6"
  },
  TermsConditionsContent7: {
    name: "TermsConditionsContent7",
    slug: "TermsConditionsContent7",
    i18n: "TermsConditionsContent7"
  },
  TermsConditionsContent8: {
    name: "TermsConditionsContent8",
    slug: "TermsConditionsContent8",
    i18n: "TermsConditionsContent8"
  },
  TermsConditionsContent9: {
    name: "TermsConditionsContent9",
    slug: "TermsConditionsContent9",
    i18n: "TermsConditionsContent9"
  },
  TermsConditionsContent10: {
    name: "TermsConditionsContent10",
    slug: "TermsConditionsContent10",
    i18n: "TermsConditionsContent10"
  },
  TermsConditionsContent11: {
    name: "TermsConditionsContent11",
    slug: "TermsConditionsContent11",
    i18n: "TermsConditionsContent11"
  },
  TermsConditionsContent12: {
    name: "TermsConditionsContent12",
    slug: "TermsConditionsContent12",
    i18n: "TermsConditionsContent12"
  },
  TermsConditionsContent13: {
    name: "TermsConditionsContent13",
    slug: "TermsConditionsContent13",
    i18n: "TermsConditionsContent13"
  },
  TermsConditionsContent14: {
    name: "TermsConditionsContent14",
    slug: "TermsConditionsContent14",
    i18n: "TermsConditionsContent14"
  },
  TermsConditionsContent15: {
    name: "TermsConditionsContent15",
    slug: "TermsConditionsContent15",
    i18n: "TermsConditionsContent15"
  },
  Accept: {
    name: "Accept",
    slug: "Accept",
    i18n: "Accept"
  },
  Product: {
    name: "Product",
    slug: "Product",
    i18n: "Product"
  },
  Welcome: {
    name: "Welcome",
    slug: "Welcome",
    i18n: "Welcome"
  },
  Genealogy: {
    name: "Genealogy",
    slug: "Genealogy",
    i18n: "Genealogy"
  },
  SentPresentations: {
    name: "Sent presentations",
    slug: "SentPresentations",
    i18n: "SentPresentations"
  },
  OpenedPresentations: {
    name: "Opened presentations",
    slug: "OpenedPresentations",
    i18n: "OpenedPresentations"
  },
  NewMembers: {
    name: "New members",
    slug: "NewMembers",
    i18n: "NewMembers"
  },
  WaitingForMeeting: {
    name: "Waiting for meeting",
    slug: "WaitingForMeeting",
    i18n: "WaitingForMeeting"
  },
  SendNeo: {
    name: "Send Neo",
    slug: "SendNeo",
    i18n: "SendNeo"
  },
  GenerateLink: {
    name: "Generate link",
    slug: "GenerateLink",
    i18n: "GenerateLink"
  },
  Trackings: {
    name: "Trackings",
    slug: "Trackings",
    i18n: "Trackings"
  },
  GenericLink: {
    name: "Generic link",
    slug: "GenericLink",
    i18n: "GenericLink"
  },
  Copy: {
    name: "Copy",
    slug: "Copy",
    i18n: "Copy"
  },
  MyLinks: {
    name: "My links",
    slug: "MyLinks",
    i18n: "MyLinks"
  },
  Link: {
    name: "Link",
    slug: "Link",
    i18n: "Link"
  },
  Indicative: {
    name: "Indicative",
    slug: "Indicative",
    i18n: "Indicative"
  },
  BeneficiaryName: {
    name: "Beneficiary Name",
    slug: "BeneficiaryName",
    i18n: "BeneficiaryName"
  },
  BeneficiaryLastName: {
    name: "Beneficiary last name",
    slug: "BeneficiaryLastName",
    i18n: "BeneficiaryLastName"
  },
  MembershipType: {
    name: "Membership Type",
    slug: "MembershipType",
    i18n: "MembershipType"
  },
  Bill: {
    name: "Bill",
    slug: "Bill",
    i18n: "Bill"
  },
  CreditCardType: {
    name: "CreditCard Type",
    slug: "CreditCardType",
    i18n: "CreditCardType"
  },
  InstallmentNumber: {
    name: "installment Number",
    slug: "InstallmentNumber",
    i18n: "InstallmentNumber"
  },
  CreditCardNumber: {
    name: "CreditCard Number",
    slug: "CreditCardNumber",
    i18n: "CreditCardNumber"
  },
  Resources: {
    name: "Resources",
    slug: "Resources",
    i18n: "Resources"
  },
  Downloads: {
    name: "Downloads",
    slug: "Downloads",
    i18n: "Downloads"
  },
  Language: {
    name: "Language",
    slug: "Language",
    i18n: "Language"
  },
  EWallet: {
    name: "EWallet",
    slug: "EWallet",
    i18n: "EWallet"
  },
  News: {
    name: "News",
    slug: "News",
    i18n: "News"
  },
  BankName: {
    name: "BankName",
    slug: "BankName",
    i18n: "BankName"
  },
  ReferenceCode: {
    name: "Reference code",
    slug: "ReferenceCode",
    i18n: "ReferenceCode"
  },
  Attachment: {
    name: "Attachment",
    slug: "Attachment",
    i18n: "Attachment"
  },
  Installments: {
    name: "Installments",
    slug: "Installments",
    i18n: "Installments"
  },
  FinanceProduct: {
    name: "Finance product",
    slug: "FinanceProduct",
    i18n: "FinanceProduct"
  },
  VerifyDate: {
    name: "Verify date",
    slug: "VerifyDate",
    i18n: "VerifyDate"
  },
  IncludeNeo: {
    name: "Do you want to buy Neo application?",
    slug: "IncludeNeo",
    i18n: "IncludeNeo"
  },
  ResidenceCountry: {
    name: "Residence country",
    slug: "ResidenceCountry",
    i18n: "ResidenceCountry"
  },
  ResidenceState: {
    name: "Residence state",
    slug: "ResidenceState",
    i18n: "ResidenceState"
  },
  Enroller: {
    name: "Enroller",
    slug: "Enroller",
    i18n: "Enroller"
  },
  Presenter: {
    name: "Presenter",
    slug: "Presenter",
    i18n: "Presenter"
  },
  AllCloser: {
    name: "All closer",
    slug: "AllCloser",
    i18n: "AllCloser"
  },
  InstallmentPay: {
    name: "Your installment is by only",
    slug: "InstallmentPay",
    i18n: "InstallmentPay"
  },
  BeingMember: {
    name: "Being member!",
    slug: "BeingMember",
    i18n: "BeingMember"
  },
  Closer: {
    name: "Closer",
    slug: "Closer",
    i18n: "Closer"
  },
  Booking: {
    name: "Booking",
    slug: "Booking",
    i18n: "Booking"
  },
  Documents: {
    name: "Documents",
    slug: "Documents",
    i18n: "Documents"
  },
  Tracking: {
    name: "Tracking",
    slug: "Tracking",
    i18n: "Tracking"
  },
  ExpirationDatePassport: {
    name: "Expiration date passport",
    slug: "ExpirationDatePassport",
    i18n: "ExpirationDatePassport"
  },
  SpecificationName: {
    name: "Specification name",
    slug: "SpecificationName",
    i18n: "SpecificationName"
  },
  SpecificationValue: {
    name: "Specification value",
    slug: "SpecificationValue",
    i18n: "SpecificationValue"
  },
  BillingData: {
    name: "Billing data",
    slug: "BillingData",
    i18n: "BillingData"
  },
  FinancialCharge: {
    name: "Financial charge",
    slug: "FinancialCharge",
    i18n: "FinancialCharge"
  },
  BVPoints: {
    name: "BV Points",
    slug: "BVPoints",
    i18n: "BVPoints"
  },
  WeekNumber: {
    name: "Week number",
    slug: "WeekNumber",
    i18n: "WeekNumber"
  },
  PreviousStage: {
    name: "Previous stage",
    slug: "PreviousStage",
    i18n: "PreviousStage"
  },
  CurrentStage: {
    name: "Current stage",
    slug: "CurrentStage",
    i18n: "CurrentStage"
  },
  Customers: {
    name: "Customers",
    slug: "Customers",
    i18n: "Customers"
  },
  TopTravelAgents: {
    name: "Top travel agents",
    slug: "TopTravelAgents",
    i18n: "TopTravelAgents"
  },
  LatestBookings: {
    name: "Latest bookings",
    slug: "LatestBookings",
    i18n: "LatestBookings"
  },
  Sales: {
    name: "Sales",
    slug: "Sales",
    i18n: "Sales"
  },
  Year: {
    name: "Year",
    slug: "Year",
    i18n: "Year"
  },
  Month: {
    name: "Month",
    slug: "Month",
    i18n: "Month"
  },
  AddPayment: {
    name: "Add payment",
    slug: "AddPayment",
    i18n: "AddPayment"
  },
  Management: {
    name: "Management",
    slug: "Management",
    i18n: "Management"
  },
  BirthDay: {
    name: "Birth day",
    slug: "BirthDay",
    i18n: "BirthDay"
  },
  BirthMonth: {
    name: "Birth month",
    slug: "BirthMonth",
    i18n: "BirthMonth"
  },
  BirthYear: {
    name: "Birth year",
    slug: "BirthYear",
    i18n: "BirthYear"
  },
  Hello: {
    name: "Hello",
    slug: "Hello",
    i18n: "Hello"
  },
  MembershipJoin: {
    name:
      "You have registrated a new member to our community. His/her distributor ID is:",
    slug: "MembershipJoin",
    i18n: "MembershipJoin"
  },
  OrderGenerated: {
    name: "We were generated an invoce with order number:",
    slug: "OrderGenerated",
    i18n: "OrderGenerated"
  },
  GenerateMembership: {
    name: "Generate membership",
    slug: "GenerateMembership",
    i18n: "GenerateMembership"
  },
  SendWelcome: {
    name: "Send welcome",
    slug: "SendWelcome",
    i18n: "SendWelcome"
  },
  NewMember: {
    name: "New member",
    slug: "NewMember",
    i18n: "NewMember"
  },
  Nickname: {
    name: "Nickname",
    slug: "Nickname",
    i18n: "Nickname"
  },
  IsFavorite: {
    name: "Is favorite",
    slug: "IsFavorite",
    i18n: "IsFavorite"
  },
  Affiliations: {
    name: "Affiliations",
    slug: "Affiliations",
    i18n: "Affiliations"
  },
  MembershipCountry: {
    name: "Membership country",
    slug: "MembershipCountry",
    i18n: "MembershipCountry"
  },
  Disabled: {
    name: "Disabled",
    slug: "Disabled",
    i18n: "Disabled"
  },
  UploadFile: {
    name: "UploadFile",
    slug: "UploadFile",
    i18n: "UploadFile"
  },
  Declined: {
    name: "Declined",
    slug: "Declined",
    i18n: "Declined"
  },
  Answer: {
    name: "Answer",
    slug: "Answer",
    i18n: "Answer"
  },
  Activities: {
    name: "Activities",
    slug: "Activities",
    i18n: "Activities"
  },
  SendMoney: {
    name: "Send money",
    slug: "SendMoney",
    i18n: "SendMoney"
  },
  Go: {
    name: "Go",
    slug: "Go",
    i18n: "Go"
  },
  Remark: {
    name: "Remark",
    slug: "Remark",
    i18n: "Remark"
  },
  Body: {
    name: "Body",
    slug: "Body",
    i18n: "Body"
  },
  OtherProducts: {
    name: "Other products",
    slug: "OtherProducts",
    i18n: "OtherProducts"
  },
  ExchangeRate: {
    name: "Exchange rate",
    slug: "ExchangeRate",
    i18n: "ExchangeRate"
  },
  IsCompany: {
    name: "It's a company",
    slug: "IsCompany",
    i18n: "IsCompany"
  },
  ConvertDistributor: {
    name: "Would you like to be a IBO Distributor?",
    slug: "ConvertDistributor",
    i18n: "ConvertDistributor"
  },
  ImportantInformation: {
    name: "Important information",
    slug: "ImportantInformation",
    i18n: "ImportantInformation"
  },
  Specifications: {
    name: "Specifications",
    slug: "Specifications",
    i18n: "Specifications"
  },
  DetailPrices: {
    name: "Detail precies",
    slug: "DetailPrices",
    i18n: "DetailPrices"
  },
  LenderServices: {
    name: "Lender services",
    slug: "LenderServices",
    i18n: "LenderServices"
  },
  SendMoneyEwallet: {
    name: "Would you like to send money to an E-wallet account?",
    slug: "SendMoneyEwallet",
    i18n: "SendMoneyEwallet"
  },
  Signature:  {
    name: "Signature",
    slug: "Signature",
    i18n: "Signature"
  },
  Investment:  {
    name: "Investment",
    slug: "Investment",
    i18n: "Investment"
  },
  Profitability:  {
    name: "Profitability",
    slug: "Profitability",
    i18n: "Profitability"
  },
  Invest:  {
    name: "Invest",
    slug: "Invest",
    i18n: "Invest"
  },
  BalanceForInvesting: {
    name: "Balance for investing",
    slug: "BalanceForInvesting",
    i18n: "BalanceForInvesting"
  },
  CustomizedAVV: {
    name: "Customized AVV",
    slug: "CustomizedAVV",
    i18n: "CustomizedAVV"
  },
  CustomizedO2T: {
    name: "Customized One2Trip",
    slug: "CustomizedO2T",
    i18n: "CustomizedO2T"
  },
  SendToBlockInvest: {
    name: "Send to Block Invest",
    slug: "SendToBlockInvest",
    i18n: "SendToBlockInvest"
  },
  Tourism: {
    name: "Tourism",
    slug: "Tourism",
    i18n: "Tourism"
  },
  Certification: {
    name: "Certification",
    slug: "Certification",
    i18n: "Certification"
  },
  Quote: {
    name: "Quote",
    slug: "Quote",
    i18n: "Quote"
  },
  QuoteHealth: {
    name: "GET A HEALTH INSURANCE QUOTE",
    slug: "QuoteHealth",
    i18n: "QuoteHealth"
  },
  QuoteTraveler: {
    name: "QUOTE VIP TRAVELER INSURANCE",
    slug: "QuoteTraveler",
    i18n: "QuoteTraveler"
  },
  QuoteMercantilVenezuela: {
    name: "QUOTE VENEZUELA PLANS",
    slug: "QuoteMercantilVenezuela",
    i18n: "QuoteMercantilVenezuela"
  },
  QuoteMercantilPanama: {
    name: "QUOTE PANAMA PLANS",
    slug: "QuoteMercantilPanama",
    i18n: "QuoteMercantilPanama"
  },
  QuoteMercantilRequest: {
    name: "HEALTH POLICY REQUEST",
    slug: "QuoteMercantilRequest",
    i18n: "QuoteMercantilRequest"
  },
  QuoteMercantilSilverYears: {
    name: "QUOTE SILVER YEARS",
    slug: "QuoteMercantilSilverYears",
    i18n: "QuoteMercantilSilverYears"
  },
  AccessToPlatforms:  {
    name: "Access to platforms",
    slug: "AccessToPlatforms",
    i18n: "AccessToPlatforms"
  },
  WeeksHonors:  {
    name: "Week's honors",
    slug: "WeeksHonors",
    i18n: "WeeksHonors"
  },
  BookingValue: {
    name: "Booking value",
    slug: "BookingValue",
    i18n: "BookingValue"
  },
  Discount: {
    name: "Discount",
    slug: "Discount",
    i18n: "Discount"
  },
  TelegramChannels:{
    name: "Telegram channels",
    slug: "Telegram channels",
    i18n: "TelegramChannels"
  },
  Categories:{
    name: "Categories",
    slug: "Categories",
    i18n: "Categories"
  },
  FeaturedNews:{
    name: "Featured news",
    slug: "FeaturedNews",
    i18n: "FeaturedNews"
  },
  RelatedArticles:{
    name: "Related articles",
    slug: "RelatedArticles",
    i18n: "RelatedArticles"
  },
  FAQs: {
    name: "FAQs",
    slug: "FAQs",
    i18n: "FAQs"
  },
  Get: {
    name: "Get",
    slug: "Get",
    i18n: "Get"
  },
  ContactFormTitle: {
    name: "ContactFormTitle",
    slug: "ContactFormTitle",
    i18n: "ContactFormTitle"
  },
  TelephoneType: {
    name: "TelephoneType",
    slug: "TelephoneType",
    i18n: "TelephoneType"
  },
  TelephoneNumber: {
    name: "Telephone Number",
    slug: "TelephoneNumber",
    i18n: "TelephoneNumber"
  },
  Class: {
    name: "Class",
    slug: "Class",
    i18n: "Class"
  },
  Airline: {
    name: "Airline",
    slug: "Airline",
    i18n: "Airline"
  },
  FlightNumber:{
    name: "Flight number",
    slug: "FlightNumber",
    i18n: "FlightNumber"
  },
  Passenger:{
    name: "Passenger",
    slug: "Passenger",
    i18n: "Passenger"
  },
  AgentCommissionValue: {
    name: "Agent commission value",
    slug: "AgentCommissionValue",
    i18n: "AgentCommissionValue"
  },
  OpenDocument: {
    name: "Open document",
    slug: "OpenDocument",
    i18n: "OpenDocument"
  },
  DownloadCertification:{
    name: "Download your IBO certificate",
    slug: "DownloadCertification",
    i18n: "DownloadCertification"
  },
  CustomerService:{
    name: "Customer service",
    slug: "CustomerService",
    i18n: "CustomerService"
  },
  AffiliationDate:{
    name: "Affiliation date",
    slug: "AffiliationDate",
    i18n: "AffiliationDate"
  },
  MyWayTopay: {
    name: "My ways to pay",
    slug: "MyWayTopay",
    i18n: "MyWayTopay"
  },
  Day:{
    name: "Day",
    slug: "Day",
    i18n: "Day"
  },
  Order:{
    name: "Order",
    slug: "Order",
    i18n: "Order"
  },
  Education:{
    name: "Education",
    slug: "Education",
    i18n: "Education"
  },
  Add:{
    name: "Add",
    slug: "Add",
    i18n: "Add"
  },
  Contact:{
    name: "Contact",
    slug: "Contact",
    i18n: "Contact"
  },
  Packages:{
    name: "Packages",
    slug: "Packages",
    i18n: "Packages"
  },
  WriteOrigin:{
    name: "WriteOrigin",
    slug: "WriteOrigin",
    i18n: "WriteOrigin"
  },
  WriteDestination:{
    name: "WriteDestination",
    slug: "WriteDestination",
    i18n: "WriteDestination"
  },
  DepartureDate:{
    name: "DepartureDate",
    slug: "DepartureDate",
    i18n: "DepartureDate"
  },
  ArrivalDate:{
    name: "ArrivalDate",
    slug: "ArrivalDate",
    i18n: "ArrivalDate"
  },
  Hotels:{
    name: "Hotels",
    slug: "Hotels",
    i18n: "Hotels"
  },
  MyCommissions: {
    name: "MyCommissions",
    slug: "MyCommissions",
    i18n: "MyCommissions"
  },
  Incentives: {
    name: "Copy",
    slug: "Copy",
    i18n: "Copy"
  },
  IssueDate: {
    name: "IssueDate",
    slug: "IssueDate",
    i18n: "IssueDate"
  },
  InitialAgent: {
    name: "InitialAgent",
    slug: "InitialAgent",
    i18n: "InitialAgent"
  },
  FinalAgent: {
    name: "FinalAgent",
    slug: "FinalAgent",
    i18n: "FinalAgent"
  },
  Administration: {
    name: "Administration",
    slug: "Administration",
    i18n: "Administration"
  },
  AgentEmail: {
    name: "AgentEmail",
    slug: "AgentEmail",
    i18n: "AgentEmail"
  },
  Publish: {
    name: "Publish",
    slug: "Publish",
    i18n: "Publish"
  },
  SupportTeam: {
    name: "Support team",
    slug: "SupportTeam",
    i18n: "SupportTeam"
  },
  Prospects: {
    name: "Prospects",
    slug: "Prospects",
    i18n: "Prospects"
  },
  LandingPage: {
    name: "LandingPage",
    slug: "LandingPage",
    i18n: "LandingPage"
  },
  URLs: {
    name: "URLs",
    slug: "URLs",
    i18n: "URLs"
  },
  LyraBinaryTree: {
    name: "Lyra binary tree",
    slug: "LyraBinaryTree",
    i18n: "LyraBinaryTree"
  },
  Marketing: {
    name: "Marketing",
    slug: "Marketing",
    i18n: "Marketing"
  },
  DownloadableResources:{
    name: "Downloadable resources",
    slug: "DownloadableResources",
    i18n: "DownloadableResources"
  }
};
